<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_21"
            data-name="Rectangle 21"
            style="fill: #ffffff"
            width="60"
            height="60"
            transform="translate(450 528)"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient"
          x1="0.197"
          x2="0.125"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
      </defs>
      <g id="Maps" transform="translate(-210 -608)">
        <g id="Maps-2" data-name="Maps" transform="translate(-240 80)">
          <rect
            id="Rectangle_21-2"
            data-name="Rectangle 21"
            :fill="background"
            width="60"
            height="60"
            transform="translate(450 528)"
          />
        </g>
        <g id="Maps-3" data-name="Maps" transform="translate(-240 80)">
          <g
            id="Mask_Group_1"
            data-name="Mask Group 1"
            style="clip-path: url(#clip-path)"
          >
            <g id="location" transform="translate(460.169 538.169)">
              <path
                id="Path_264"
                data-name="Path 264"
                style="fill: url(#linear-gradient)"
                d="M14.5,47.386a33.673,33.673,0,0,0,8.773,1.033,33.669,33.669,0,0,0,8.773-1.033c2.886-.815,4.349-2,4.349-3.507s-1.463-2.692-4.349-3.507c-.641-.181-1.337-.34-2.078-.475q-.6,1.043-1.264,2.146c.82.123,1.59.274,2.289.452,2.124.541,2.9,1.15,3.059,1.384-.16.234-.935.843-3.059,1.384a32.087,32.087,0,0,1-7.32.83c-.132.01-.265.015-.4.015s-.267-.005-.4-.015a32.083,32.083,0,0,1-7.32-.83c-2.124-.541-2.9-1.15-3.059-1.384.159-.234.935-.843,3.059-1.384.7-.178,1.469-.329,2.289-.452q-.661-1.1-1.264-2.146c-.741.135-1.437.294-2.078.475-2.886.815-4.349,2-4.349,3.507S11.613,46.57,14.5,47.386Z"
                transform="translate(-3.44 -10.524)"
              />
              <path
                id="Path_265"
                data-name="Path 265"
                style="fill: url(#linear-gradient)"
                d="M24.461,30.26a2.925,2.925,0,0,0,2.514-1.41c3.781-6.186,8.288-14.339,8.288-18.049a10.8,10.8,0,0,0-21.6,0c0,3.71,4.507,11.863,8.288,18.049A2.926,2.926,0,0,0,24.461,30.26Zm-4.342-20.2A4.342,4.342,0,1,1,24.461,14.4,4.346,4.346,0,0,1,20.119,10.06Z"
                transform="translate(-4.63 3.374)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>